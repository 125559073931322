@import 'mixins';

.tools {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
  grid-template-areas:
    'fl mp bs'
    'rt mp bs';

  .toolsItem {
    position: relative;
    overflow: hidden;
    flex: 1;
    padding: 24px;
    background-color: var(--color-accent-10);
    border-radius: 12px;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
      padding: 18px;
    }

    &:nth-child(1) {
      grid-area: fl;
    }
    &:nth-child(2) {
      grid-area: rt;
    }
    &:nth-child(3) {
      grid-area: mp;

      img {
        height: 200px;
        margin: auto auto 24px auto;
      }
    }
    &:nth-child(4) {
      grid-area: bs;

      img {
        height: 200px;
        margin: auto auto 24px auto;
      }
    }

    .toolsItemTitle,
    .toolsItemSubtitle,
    .toolsItemButton {
      position: relative;
      z-index: 5;
    }

    .toolsItemTitle {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 18px;
    }
    .toolsItemSubtitle {
      opacity: 0.65;
    }
    .toolsItemButton {
      @include button-blue;

      width: max-content;
      margin-top: 24px !important;
      padding: 14px 18px;
      font-weight: 600;
    }

    a {
      display: block;
      width: max-content;
      transition: color 200ms;

      &:hover,
      &:active {
        color: var(--color-accent-100);
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    grid-template-areas:
      'fl rt'
      'mp bs';
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'fl'
      'rt'
      'mp'
      'bs';

    .toolsItemButton,
    a {
      width: 100% !important;
    }
  }
}
