@import 'mixins';

.splitter {
  display: flex;
  color: white;

  .text {
    flex: 1;
    padding: 38px 0;

    .title {
      margin: 0;
    }
    .subtitle {
      margin-top: 4px;
      opacity: 0.8;
    }

    @media (max-width: 992px) {
      padding: 18px 0;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input {
      width: 340px;
      position: relative;
      background-color: white;
      font-size: 25px;
      overflow: hidden;
      border-radius: 12px;

      .inputZone {
        outline: none;
        width: 100%;
        border: none;
        height: 61px;
        padding: 0 58px 0 18px;
        box-sizing: border-box;
        font-weight: 500;

        &::placeholder {
          opacity: 0.5;
        }
      }
      .inputButton {
        @include button-green;

        position: absolute;
        top: 4px;
        right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 53px;
        height: 53px;

        svg {
          position: relative;
          flex-shrink: 0;
          top: 1px;
          left: -0.5px;
        }
      }

      @media (max-width: 992px) {
        width: auto;
      }
    }
    .help {
      font-size: 14px;
      opacity: 0.6;
      margin-top: 8px;
      text-align: center;
    }

    @media (max-width: 992px) {
      padding: 0 0 18px 0;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    margin: auto !important;
  }
}
