.block {
  .title {
    text-align: left;

    @media (min-width: 992px) {
      text-align: center;
    }
  }
  .features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;

    .featuresItem {
      .featuresItemText {
        position: relative;
        font-size: 1.2rem;
        z-index: 1;
        font-weight: 600;
      }
      .featuresItemBar {
        position: relative;
        margin-top: 8px;
        border-radius: 12px;
        height: 40px;
        background-color: var(--color-accent-100);

        .featuresItemNumber {
          position: absolute;
          right: 20px;
          top: -28px;
          font-size: 5rem;
          font-family: 'Dela Gothic One', sans-serif;
          text-shadow: 10px 8px 0px #000;
          color: white;
          line-height: 1;
          -webkit-text-stroke: 2px #000;

          @media (max-width: 768px) {
            font-size: 4rem;
            top: -20px;
          }
        }
      }
      .featuresItemImage {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 12px;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 225px;
          height: 100%;
        }
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
