.block {
  @media (max-width: 992px) {
    display: none;
  }
}

.widget {
  position: relative;
  height: 480px;

  @media (max-width: 992px) {
    height: 300px;
    margin: -12px;
  }
  @media (max-width: 768px) {
    height: 260px;
  }
  @media (max-width: 576px) {
    height: 180px;
  }

  .widgetImage {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--color-neutral-100);
    border-radius: 12px;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }
  .widgetSearch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: max-content;
    margin: auto;
    z-index: 1;
    user-select: none;

    .widgetSearchSlogan {
      font-family: 'Dela Gothic One', sans-serif;
      font-size: 4rem;
      color: white;
      text-shadow: 10px 8px 0px black;
      margin-bottom: 32px;
      text-transform: uppercase;
      -webkit-text-stroke: 2px #000;
    }
  }
  .widgetSearchPattern {
    position: absolute;
    inset: 0;
    overflow: hidden;

    img {
      position: absolute;
      right: -480px;
      bottom: -420px;
      width: 1000px;
      opacity: 0.15;
    }
  }
}
