.commercial {
  display: none;
  align-items: center;
  margin-top: 8px;
  background-color: #ffde5a;
  padding-left: 14px;
  border-radius: 8px;
  text-align: center;
  width: max-content;
  cursor: pointer;
  font-weight: 600;
  color: var(--color-text-100) !important;
  line-height: 0;
  font-size: 0.8rem;
  box-shadow: 0 0 12px rgba(255, 222, 90, 0.7);
  transition: box-shadow 200ms;

  svg {
    margin-left: 8px;
    color: #77682c;
    transition: margin-left 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    box-shadow: 0 0 32px rgba(255, 222, 90, 0.8);

    svg {
      margin-left: 12px;
    }
  }

  @media (min-width: 992px) {
    display: flex;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
