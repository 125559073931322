@import 'mixins';

.carousel {
  position: relative;

  .carouselButton {
    @include button-gray;

    position: absolute;
    top: calc(50% - 25px);
    height: 46px;
    width: 46px;
    border-radius: 50%;
    z-index: 10;
    box-shadow: var(--shadow-default);

    &.carouselButtonPrevious {
      left: -25px;

      svg {
        position: relative;
        right: 1px;
      }
    }
    &.carouselButtonNext {
      right: -25px;

      svg {
        position: relative;
        left: 1px;
      }
    }

    svg {
      flex-shrink: 0;
    }

    @media (max-width: 1250px) {
      display: none;
    }
  }
  .carouselContent {
    display: flex;
    overflow: hidden;
    padding: 24px 0;
    margin: -24px 0;

    @media (max-width: 1250px) {
      scroll-snap-type: x mandatory;
      padding: 24px;
      margin: -24px;
    }
    @media (max-width: 992px) {
      overflow: auto;
      padding: 12px;
      margin: -12px;
    }

    .carouselItem {
      color: inherit;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      overflow: hidden;
      background-color: white;
      box-sizing: border-box;
      border: 1px solid var(--color-neutral-100);
      transition: all 300ms;
      flex-shrink: 0;
      width: 270px;
      transform: translate3d(0, 0, 0);
      scroll-snap-align: center;

      &:not(:first-child) {
        margin-left: 12px;
      }

      &:hover {
        color: inherit;
        box-shadow: var(--shadow-default);
      }

      .carouselItemGallery {
        position: relative;

        .carouselItemCounter {
          position: absolute;
          left: 8px;
          bottom: 8px;
          z-index: 5;
          background-color: white;
          padding: 8px;
          border-radius: 6px;
          box-shadow: var(--shadow-default);
          font-size: 0.7rem;
        }
        .carouselItemButtons {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          overflow: hidden;
          border-radius: 16px;

          svg {
            filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
          }

          .carouselItemButtonLeft {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 40px;
            z-index: 5;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms;

            &:active {
              transform: scale(0.85);
            }
          }
          .carouselItemButtonRight {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 40px;
            z-index: 5;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms;

            &:active {
              transform: scale(0.85);
            }
          }
        }
        .carouselItemPhotos {
          height: 220px;
          display: flex;
          overflow: hidden;
          scroll-snap-type: x mandatory;
          flex-shrink: 0;
          padding: 2px;
          box-sizing: border-box;

          .carouselItemPhoto {
            position: relative;
            background-color: var(--color-neutral-100);
            color: var(--color-text-100);
            border-radius: 10px;
            width: 100%;
            overflow: hidden;
            scroll-snap-align: center;
            flex-shrink: 0;
            transform: translate3d(0, 0, 0);

            &:not(:first-child) {
              margin-left: 8px;
            }
          }
          .carouselItemPhotoHash {
            z-index: 1;
            transition: opacity 500ms;
          }
          .carouselItemPhotoMore {
            display: flex;

            .carouselItemPhotoMoreText {
              font-size: 2rem;
              font-weight: 600;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              height: 100%;

              span {
                display: block;
                font-size: 16px;
                margin-top: 4px;
                color: var(--color-text-70);
                font-weight: 500;
              }
            }
          }

          @media (max-width: 992px) {
            overflow: auto hidden;
          }
        }
      }
      .carouselItemLabels {
        position: absolute;
        left: 8px;
        top: 8px;
        z-index: 5;

        .carouselItemLabelsItem {
          padding: 8px;
          z-index: 4;
          border-radius: 6px;
          font-size: 0.7rem;
          width: max-content;
          font-weight: 500;

          &:not(:first-child) {
            margin-top: 4px;
          }

          &.yellow {
            background-color: #ffde5a;
            box-shadow: 0 0 12px rgba(255, 222, 90, 0.7);
          }
          &.red {
            background-color: #fb5f5f;
            box-shadow: 0 0 12px rgb(232, 108, 108, 0.7);
            color: white;
          }
          &.blue {
            background-color: #51a4f7;
            box-shadow: 0 0 12px rgba(81, 164, 247, 0.7);
            color: white;
          }
        }
      }
      .carouselItemContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 12px 18px 18px 18px;
        box-sizing: border-box;

        .carouselItemAddress {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 2px;
          font-size: 0.8rem;
          color: var(--color-text-70);
        }
        .carouselItemPrice {
          display: flex;
          font-size: 28px;
          font-weight: 700;
          white-space: nowrap;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
            flex-shrink: 0;

            &.green {
              color: #90e184;
            }
            &.red {
              color: #ff8c8c;
            }
          }
        }
        .carouselItemOldPrice {
          text-decoration: line-through;
          color: var(--color-text-40);
        }
        .carouselItemInfo {
          margin-top: 24px;

          .carouselItemProperty {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            page-break-inside: avoid;
            break-inside: avoid-column;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 600;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              color: var(--color-text-70);
              flex: 1 1;
              display: flex;
              align-items: baseline;
              white-space: nowrap;
              font-weight: 500;

              &:after {
                content: '';
                flex-grow: 1;
                margin: 0 8px;
                border-bottom: 1px solid #ececec;
              }
            }
          }
        }
        .carouselItemButton {
          @include button-blue;

          padding: 12px 24px;
          width: 100%;
          text-align: center;
          margin-top: auto;
        }
      }
      .carouselItemActions {
        display: flex;
        margin: 0 8px 8px 8px;

        .carouselItemFavorites {
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          width: 46px;
          height: 46px;
          border-radius: 12px;
          margin-left: 8px;
          flex-shrink: 0;
          color: inherit;
          transition: all 200ms;

          svg {
            transition: fill 200ms;
          }

          &:hover {
            color: #fb5f5f;
          }
          &:active {
            transform: scale(0.9);
          }

          &.active {
            color: #fb5f5f;
            background-color: rgba(232, 108, 108, 0.15);

            svg {
              fill: #fb5f5f;
            }
          }
        }
      }

      @media (max-width: 992px) {
        min-width: 300px;
        max-width: 300px;
        width: 100%;
        flex-shrink: 0;
        scroll-snap-align: center;
      }
      @media (max-width: 420px) {
        max-width: calc(100% - 48px);
      }
    }
    .carouselItemMore {
      @include button-blue;

      width: 270px;
      scroll-snap-align: center;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      padding: 24px;
      box-sizing: border-box;
      text-align: center;
      flex-direction: column;
      box-shadow: var(--shadow-default);
      margin-left: 12px;
      flex-shrink: 0;

      span {
        font-size: 25px;
        margin: auto;
      }
    }
    .carouselItemDummy {
      flex-shrink: 0;
      border-right: 12px solid transparent;

      @media (min-width: 576px) {
        display: none;
      }
    }
  }
}
