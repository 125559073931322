.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  overflow: auto;
  color: inherit;

  .checkboxButton {
    color: inherit;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 0 12px;
    height: 100%;
    min-width: 46px;
    font-weight: 500;
    background-color: white;
    line-height: 1;
    transition: all 200ms;

    &:hover {
      color: var(--color-accent-100);
      border-color: var(--color-accent-100);
      background-color: rgba(41, 105, 224, 0.1);
    }
    &:active {
      transform: scale(0.95);
    }
    &:focus {
      color: var(--color-accent-100);
      background-color: rgba(41, 105, 224, 0.2);
      border-color: var(--color-accent-100);
    }

    &.active {
      color: white;
      background-color: var(--color-accent-100);
    }

    @media (max-width: 992px) {
      width: 100%;
      min-width: 80px;
    }
  }
  .checkboxSplitter {
    width: 1px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 4px;
    flex-shrink: 0;
  }

  @media (max-width: 992px) {
    justify-content: flex-start;
  }
}
