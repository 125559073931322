@import 'mixins';

.banner {
  position: relative;
  padding: 44px 52px;
  color: white;
  background-color: var(--color-accent-100);
  border-radius: 12px;
  box-sizing: border-box;
  overflow: hidden;

  .bannerIllustration {
    position: absolute;
    right: -100px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 750px;

      @media (min-width: 768px) and (max-width: 992px) {
        width: 550px;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .bannerTitle,
  .bannerSubtitle,
  .bannerButton {
    position: relative;
    z-index: 5;
  }

  .bannerTitle {
    margin: 0;
  }
  .bannerSubtitle,
  .bannerButton {
    margin-top: 32px !important;
  }
  .bannerSubtitle {
    opacity: 0.8;
    max-width: 35%;
    font-size: 18px;

    @media (max-width: 1200px) {
      max-width: 40%;
    }
    @media (max-width: 992px) {
      max-width: 40%;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .bannerButton {
    @include button-white;

    border-radius: 12px;
    font-size: 21px;
    padding: 18px 32px;
    font-weight: 700;
    line-height: 1;

    @media (max-width: 768px) {
      font-size: 18px;
      font-weight: 600;
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    padding: 18px 12px;
    margin: 0 -12px;
    border-radius: 0;
  }
}
